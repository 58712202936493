import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	FeatureStateSymbol,
	Props,
	StructureAPI,
	BrowserWindowSymbol,
} from '@wix/thunderbolt-symbols'
import {
	CompareDataDeep,
	CreateTransition,
	GetNormalizedTransitionDuration,
	HaveEqualBackgrounds,
	PageTransitionsFactory,
	SetBackgroundVisibility,
	TransitionParams,
	PageTransitionsWillMountFactory,
} from './types'
import { name, PageTransitionsCompletedSymbol } from './symbols'
import { Animations } from 'feature-animations'
import { isSSR } from '@wix/thunderbolt-commons'

const STYLE_OVERRIDES_ID = 'STYLE_OVERRIDES_ID'
const BACKGROUND_ID_PREFIX = 'pageBackground_'

const getNormalizedTransitionDuration: GetNormalizedTransitionDuration = (defaultDuration, params) => {
	const width = params.width
	const height = params.height

	let normalizedDuration = defaultDuration
	if (width) {
		normalizedDuration *= params.siteWidth!
	} else if (height) {
		normalizedDuration *= Math.max(height, params.screenHeight!) / params.screenHeight!
	}

	return Math.min(normalizedDuration, 1.2)
}

const compareDataDeep: CompareDataDeep = (prevData, currentData, refKeys, propsToCheck) => {
	// @ts-ignore
	const equal = propsToCheck.every((key: string) => (prevData && prevData[key]) === (currentData && currentData[key]))
	return (
		equal &&
		refKeys.every((ref: string) =>
			prevData || currentData
				? // @ts-ignore
				  compareDataDeep(prevData && prevData[ref], currentData && currentData[ref], refKeys, propsToCheck)
				: true
		)
	)
}

const haveEqualBackgrounds: HaveEqualBackgrounds = (prevPageBackground, currentPageBackground) => {
	// prev page background media data
	const prevMediaData = prevPageBackground.mediaRef
	const prevMediaDataType = prevMediaData && prevMediaData.type
	// current page background media data
	const currentMediaData = currentPageBackground.mediaRef
	const currentMediaDataType = currentMediaData && currentMediaData.type

	const isOnlyColor = !prevMediaData && !currentMediaData
	const isMediaTypeEqual = isOnlyColor || prevMediaDataType === currentMediaDataType
	const shouldIgnoreColor = prevMediaDataType === 'WixVideo' && isMediaTypeEqual

	const refKeys = ['mediaRef', 'imageOverlay']
	let propsToCheck = [
		'type',
		'alignType',
		'fittingType',
		'scrollType',
		'colorOverlay',
		'colorOverlayOpacity',
		'color',
		'videoId',
		'uri',
		'opacity',
	]
	if (shouldIgnoreColor) {
		const colorIndex = propsToCheck.indexOf('color')
		propsToCheck.splice(colorIndex, 1)
	} else if (isOnlyColor) {
		propsToCheck = ['color']
	}

	return isMediaTypeEqual && compareDataDeep(prevPageBackground, currentPageBackground, refKeys, propsToCheck)
}

const setBackgroundVisibility: SetBackgroundVisibility = (props, pageBgId, show) => {
	const { styles: existingStyles } = props.get(STYLE_OVERRIDES_ID) || {}
	props.update({
		[STYLE_OVERRIDES_ID]: {
			styles: {
				...existingStyles,
				[pageBgId]: {
					...(show && { visibility: 'visible !important' }),
				},
			},
		},
	})
}

const pageTransitionsFactory: PageTransitionsFactory = (
	structureAPI,
	props,
	window,
	featureState,
	pageFeatureConfig,
	pageTransitionsCompleted,
	animations
) => {
	const windowSize = {
		width: isSSR(window) ? 0 : window!.document.documentElement.clientWidth,
		height: isSSR(window) ? 0 : window!.document.documentElement.clientHeight,
	}

	const TRANSITIONS_MAP: { [transitionName: string]: Partial<TransitionParams> } = {
		SlideHorizontal: {
			siteWidth: pageFeatureConfig.siteWidth,
			width: windowSize.width,
			ease: 'Cubic.easeOut',
		},
		SlideVertical: {
			screenHeight: windowSize.height,
			height: windowSize.height,
			reverse: true,
			ease: 'Cubic.easeInOut',
		},
		OutIn: {
			sourceEase: 'Strong.easeOut',
			destEase: 'Strong.easeIn',
		},
		CrossFade: {
			sourceEase: 'Sine.easeInOut',
			destEase: 'Quad.easeInOut',
		},
	}

	const createTransition: CreateTransition = (animatorManager, srcId, targetId, duration, params, callbacks = {}) => {
		const { transitionName, delay } = pageFeatureConfig.pageTransition

		return animatorManager.runSequence(
			[
				{
					type: 'Transition',
					data: {
						name: transitionName,
						srcId,
						targetId,
						duration,
						delay,
						params,
					},
				},
				{
					type: 'Animation',
					data: {
						name: 'BaseClear',
						targetId: [srcId, targetId],
						params: {
							props: 'opacity,x,y',
							immediateRender: false,
						},
					},
				},
			],
			callbacks
		)
	}

	return {
		async pageDidMount(pageId) {
			const state = featureState.get()
			const prevPageBackground = state && state.pageBackground
			const prevPageId = state && state.pageId
			const currentPageBackground = pageFeatureConfig.pageBackground

			const prevBgId = `${BACKGROUND_ID_PREFIX}${prevPageId}`
			const currentBgId = `${BACKGROUND_ID_PREFIX}${pageId}`

			if (animations && prevPageBackground && state.nextTransitionEnabled) {
				const animatorManager = await animations.getInstance()

				const { transitionName } = pageFeatureConfig.pageTransition
				const params = TRANSITIONS_MAP[transitionName]

				const defaultDuration = animatorManager.getAnimationProperties(transitionName).defaultDuration
				const duration = getNormalizedTransitionDuration(defaultDuration, params)

				createTransition(animatorManager, prevPageId, pageId, duration, params, {
					onComplete: () => {
						pageTransitionsCompleted.notifyPageTransitionsCompleted(pageId)
					},
				})

				if (!haveEqualBackgrounds(prevPageBackground, currentPageBackground)) {
					createTransition(animatorManager, prevBgId, currentBgId, duration, params, {
						onStart: () => {
							setBackgroundVisibility(props, currentBgId, true)
						},
						onComplete: () => {
							setBackgroundVisibility(props, prevBgId, false)
							structureAPI.removePageBackgroundFromRenderedTree(prevPageId)
						},
					})
				} else {
					setBackgroundVisibility(props, currentBgId, true)
					setBackgroundVisibility(props, prevBgId, false)
					structureAPI.removePageBackgroundFromRenderedTree(prevPageId)
				}
			} else {
				setBackgroundVisibility(props, currentBgId, true)
				prevPageId && setBackgroundVisibility(props, prevBgId, false)
				structureAPI.removePageBackgroundFromRenderedTree(prevPageId)
				pageTransitionsCompleted.notifyPageTransitionsCompleted(pageId)
			}

			featureState.update(() => ({
				pageBackground: currentPageBackground,
				pageId,
				nextTransitionEnabled: true,
			}))
		},
		disableNextTransition: () =>
			featureState.update(() => ({
				...featureState.get(),
				nextTransitionEnabled: false,
			})),
	}
}

export const PageTransitions = withDependencies(
	[
		StructureAPI,
		Props,
		BrowserWindowSymbol,
		named(FeatureStateSymbol, name),
		named(PageFeatureConfigSymbol, name),
		PageTransitionsCompletedSymbol,
		optional(Animations),
	],
	pageTransitionsFactory
)

const pageTransitionsWillMountFactory: PageTransitionsWillMountFactory = (props, featureState) => {
	return {
		pageWillMount(pageId) {
			if (!process.env.browser) {
				const pageBackgroundId = `${BACKGROUND_ID_PREFIX}${pageId}`
				setBackgroundVisibility(props, pageBackgroundId, true)
			} else {
				const state = featureState.get()
				const prevPageId = state && state.pageId

				if (prevPageId) {
					const prevBgId = `${BACKGROUND_ID_PREFIX}${prevPageId}`
					setBackgroundVisibility(props, prevBgId, true)
				}
			}
		},
	}
}

export const PageTransitionsWillMount = withDependencies(
	[Props, named(FeatureStateSymbol, name)],
	pageTransitionsWillMountFactory
)
